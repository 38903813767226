import { render, staticRenderFns } from "./CustomDialog.vue?vue&type=template&id=4e5e991a&scoped=true&"
import script from "./CustomDialog.vue?vue&type=script&lang=ts&"
export * from "./CustomDialog.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e5e991a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
installComponents(component, {VCard,VContainer,VDialog})
