
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
  import FormTitle from '@/components/forms/FormTitle.vue'

@Component({
  components: { FormTitle },
})
  export default class CustomDialog extends Vue {
  @Prop({ type: Boolean, default: false }) value!: boolean
  @Prop({ type: String, default: '' }) title!: string
  open = false

  @Watch('value', { immediate: true })
  onValueChange () {
    this.open = this.value
  }
  }
