import { Component } from 'vue-property-decorator'
import { PurchaseView } from '@/components/forms/view/PurchaseView'
import { isValidNumber, parseToNumber } from '@/utils/general'

@Component
export class ExpenseView extends PurchaseView {
  async updatePaymentRecipient (viewData) {
    const { backupPaymentRecipient, paymentOrderItem, purchaseOrder, backup } = viewData
    const isPurchaseForm = backup && 'purchaseForm' in backup && backup.purchaseForm

    if (isValidNumber(isPurchaseForm?.price)) {
      await this.pushData({
        model: 'PurchaseOrder',
        fields: {
          id: purchaseOrder.id,
          agreed_amount: isPurchaseForm?.price,
        },
      })

      await this.pushData({
        model: 'PaymentOrder',
        fields: {
          id: paymentOrderItem.order.id,
          amount: isPurchaseForm?.price,
        },
      })
    }

    await this.updateRecipient(viewData, backupPaymentRecipient?.id)
  }

  async updateRecipient (viewData, id) {
    const { formData: { beneficiary, payments }, paymentRecipient } = viewData

    const recipients = paymentRecipient.paymentOrderItem.recipients
    const isRecipient = recipients.find(recipient => recipient.person.id === beneficiary.id)
    let recipient
    if (isRecipient) {
      recipient = await this.pushData({
        model: 'PaymentRecipient',
        fields: { id: isRecipient.id, id_person: beneficiary.id },
      })
    } else {
      if (payments.length === 1 && id) {
        recipient = await this.pushData({
          model: 'PaymentRecipient',
          fields: { id, id_person: beneficiary.id },
        })
      } else {
        const fields = {
          id_person: beneficiary.id,
          id_payment_order_item: paymentRecipient.paymentOrderItem.id,
        }
        recipient = await this.pushData({
          model: 'PaymentRecipient',
          fields,
        })
      }
    }
    await this.updateCreatedPayment(viewData, recipient.id)
  }

  async updateCreatedPayment (viewData, id) {
    const {
      formData: { expense, paymentType, uniquePayment, account, file },
      deal,
    } = viewData

    const { paymentStatus: { pending } } = this

    const isDifferent = parseToNumber(uniquePayment?.amount) !== parseToNumber(expense) ||
      uniquePayment?.type.id !== paymentType?.id ||
      uniquePayment?.recipientAccount?.id !== account?.id

    if (isDifferent) {
      const fields = {
        id: uniquePayment?.id,
        amount: parseInt(expense),
        id_process_status: pending[0].id,
        id_payment_type: paymentType.id,
        id_payment_recipient: id,
        id_deal: deal.id,
        comment: '',
        id_recipient_account: account?.id || null,
      }

      if (uniquePayment?.id) {
        delete fields.id_deal
      }

      await this.pushData({
        model: 'Payment',
        fields,
      })
    } else {
      await this.pushData({
        model: 'Payment',
        fields: {
          id: uniquePayment?.id,
          comment: '',
          id_process_status: pending[0].id,
        },
      })
    }

    if (file?.length) {
      const process = await this.fetchData({
        query: { name: 'find', model: 'Process' },
        filter: { table_name: { _eq: 'payment' } },
      })
      const fileInfo = await this.fetchData({
        query: { name: 'find', model: 'FileParameter' },
        filter: { _and: [{ process: { table_name: { _eq: 'payment' } } }, { name: { _eq: 'spending_support' } }] },
      })

      const backup = {
        properties: {
          required: true,
          properties: {
            label: fileInfo[0].description,
            multiple: fileInfo[0].multiple,
            accept: fileInfo[0].fileType.mimes,
            fileTypeId: fileInfo[0].fileType.id,
            name: fileInfo[0].name,
            required: fileInfo[0].required,
          },
        },
      }

      await this.handleFileType(file, { properties: backup }, process[0].id, uniquePayment?.id, fileInfo)
    }
  }
}
